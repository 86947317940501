import React from 'react';
import Img from "gatsby-image"

import '../scss/album.scss';

export default function Album(props) {
  return (
    <div className="album-container col-md-6" style={{ textAlign: 'center' }}>
      <div className="album">
        <Img fluid={props.image} />
          <div className="album__overlay"></div>
          <div className="album__info">
            <div className="album__info--text">
              <h4>{props.title}</h4>
              <p>{props.description}</p>
              <a href={props.spotify} target="_blank" rel="noreferrer" className="btn btn-round btn-small icon icon__spotify">Spotify</a>
              <a href={props.apple} target="_blank" rel="noreferrer" className="btn btn-round btn-small icon icon__apple">Apple</a>
            </div>
          </div>
      </div>
    </div>
  )
}