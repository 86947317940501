import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Album from "../components/album"
import Img from "gatsby-image"
import BGVideo from "../images/bg-video.mp4"


function getYear() {
  return new Date().getFullYear();
}

export default function Home({ data }) {
  return (
    <>
      <Layout>
        <SEO title="Ballteam Playlists" keywords={[`music`, `rap`, `hip-hop`, `playlists`, `spotify`, `apple music`, `austin`, `texas`]} />
        <div className="bg-video">
          <video autoPlay muted loop>
            <source src={BGVideo} type="video/mp4" />
          </video>
        </div>
        <div className="row">
          <div className="col-sm-10" style={{ textAlign: 'center', margin: 'auto' }}>
            <Img fadeIn={false} loading="eager" fixed={data.logo.childImageSharp.fixed} />
            <h1>Ballteam Playlists</h1>
            <p className="lead">A carefully curated selection of current music on playlists available on Spotify and Apple Music.</p>
          </div>
        </div>
        <div className="albums row">
          <div className="col-lg-10" style={{ textAlign: 'center', margin: 'auto' }}>
            <div className="row">
              <Album
                spotify="https://open.spotify.com/user/12181177341/playlist/7obgJFmghXSR3ywAzmIjtr?si=shv4xq-3TwGzEh4_7j0wQw"
                apple="https://music.apple.com/us/playlist/rap-current/pl.u-6mo44bKtZZkdLv?ls=1&at=11lEW&ct=ezlne2"
                image={data.playlist1.childImageSharp.fluid}
                title="Rap Current"
                description="Current underground and popular rap. Individually curated and frequently updated."
              />
              <Album
                spotify="https://open.spotify.com/user/12181177341/playlist/1iuLKpX61OH9UiCbnSUCjR?si=ujnUUIg_Q9602wuAO1TQ7w"
                apple="https://music.apple.com/us/playlist/texas-rap-current/pl.u-LdbqqK5tkkm4PB?ls=1&at=11lEW&ct=c6mns1"
                image={data.playlist2.childImageSharp.fluid}
                title="Texas Rap Current"
                description="Current Texas rap hits. Bringing the focus back to the state that helped birth modern day trap and rap music."
              />
              <Album
                spotify="https://open.spotify.com/playlist/5jC2pTtkX8lX2pT8r9dcuE"
                apple="https://music.apple.com/us/playlist/austin-texas-rap/pl.u-ZmbllNofzzPB26?fbclid=IwAR3EJcfEA9FpF5cpL0i07iAXpI6xVFWV97Qw6vttobR4N8nFW2fSp0RUeJ4&ls=1&at=11lEW&ct=hnfs9q"
                image={data.playlist3.childImageSharp.fluid}
                title="Austin Rap Current"
                description="Good rap music from Austin, Texas."
              />
              <Album
                spotify="https://open.spotify.com/playlist/2mvkVv5zSD2k3kOkZIhzDs"
                apple="https://music.apple.com/us/playlist/austin-originals/pl.u-PDb44EDTkkVqR5?ls=1&at=11lEW&ct=38v8jn"
                image={data.playlist4.childImageSharp.fluid}
                title="Austin Originals"
                description="A diverse selection of bands, instrumental, pop and r&b music from Austin, Texas."
              />
            </div>
          </div>
        </div>
      </Layout>
      <footer>
        <p className="small" style={{ textAlign: 'center' }}>&copy; Ballteam Playlists {getYear()}</p>
      </footer>
    </>
  )
}

export const query = graphql`
  {
    logo: file(relativePath: { eq: "ballteam-white.png" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    playlist1: file(relativePath: { eq: "rap-current.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    playlist2: file(relativePath: { eq: "texas-rap-current.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    playlist3: file(relativePath: { eq: "austin-rap-current.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    playlist4: file(relativePath: { eq: "austin-originals.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`